import * as React from "react"
import { Link } from "gatsby"

import Facebook from '../images/icons/facebook-white.svg'
import Linkedin from '../images/icons/linkedin-white.svg'
import Instagram from '../images/icons/instagram-white.svg'
import Medium from '../images/icons/medium-white.svg'


const Footer = () => {
  return (
    <div className="w-full bg-welement-purple text-welement-white py-20 px-16">
      <div className="grid sm:grid-cols-2 md:grid-cols-4 gap-12 md:gap-4">
        <div className="col-span-1 md:col-span-2">
          <p className="body-l wel-mb-s">
            Got a project?<br />
            We'd love to help.
          </p>
          <Link to="/contact" className="btn btn-red">get in Touch</Link>

        </div>
        <div className="hidden md:block">
          <ul className="body-l">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/solutions">Solutions</Link></li>
            <li><Link to="/prefab">Prefab</Link></li>
            <li><Link to="/our-story">Our Story</Link></li>
            <li><Link to="/portfolio">Portfolio</Link></li>
            <li><Link to="/contact">Contact</Link></li>
          </ul>

        </div>
        <div>
          <div className="wel-mb-m">
            <h4 className="title-xs wel-mb-xs">Language</h4>
            <ul className="">
              <li><a href="/" className="opacity-100">🇬🇧 In English</a></li>
              <li><a href="/et" className="opacity-50">🇪🇪 Eesti keeles</a></li>
              <li><a href="/no" className="opacity-50">🇳🇴 På norsk</a></li>
            </ul>
          </div>
          <div className="wel-mb-s">
            <h4 className="title-xs wel-mb-xs">Contact</h4>
            <p><a href="tel:+37253334302">372 5333 4302</a><br />
              <a href="mailto:info@welement.ee">info@welement.ee</a><br />
              Klaasi 14, Tartu<br />Estonia, EU</p>
          </div>

          <div className="wel-mb-s">
            <a href="https://www.linkedin.com/company/welement-as/" className="inline-block mr-1"><img src={Linkedin} alt="Welement LinkedIn" className="h-10" /></a>
            <a href="https://www.facebook.com/welementprefab/" className="inline-block mr-1"><img src={Facebook} alt="Welement Facebook" className="h-10" /></a>
            <a href="https://www.instagram.com/welement_prefab/" className="inline-block mr-1"><img src={Instagram} alt="Welement Instagram" className="h-10" /></a>
            <a href="https://medium.com/welement" className="inline-block"><img src={Medium} alt="Welement Medium" className="h-10" /></a>
          </div>

          <p><a className="body-s opacity-50" href="/terms.pdf" target="_blank">Terms of data protection</a></p>
        </div>
      </div>
    </div>
  )
}

export default Footer;
