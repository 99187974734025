import * as React from "react"
import Helmet from '../images/icons/helmet.svg'
import Angle from '../images/icons/angle.svg'
import Key from '../images/icons/key.svg'

const PrefabFeatures = () => {
  return (
    <div className="section-1-2-center">
      <h2 className="title wel-mb-l">Why Prefab?</h2>
      <div className="grid md:grid-cols-3 gap-12 md:gap-8 w-full sm:w-2/3 md:w-full mx-auto">
        <div className="text-center">
          <img src={Angle} alt="Improved Quality" className="h-24 mx-auto wel-mb-s" />
          <h3 className="title-xs">IMPROVED QUALITY</h3>
          <p>Quality is managed in a systemized way to optimize work processes onsite.</p>
        </div>

        <div className="text-center">
          <img src={Key} alt="Factory Setting" className="h-24 mx-auto wel-mb-s" />
          <h3 className="title-xs">FACTORY SETTING</h3>
          <p>Factory conditions preclude weather factors that may complicate construction.</p>
        </div>

        <div className="text-center">
          <img src={Helmet} alt="Installation Speed" className="h-24 mx-auto wel-mb-s" />
          <h3 className="title-xs">INSTALLATION SPEED</h3>
          <p>Precisely made parts of the house allow quick installation on the site.</p>
        </div>
      </div>
    </div>
  )
}

export default PrefabFeatures;
