import * as React from "react"
import { Link } from "gatsby"
import WelementLogo from '../images/welement-logo.svg'
import Facebook from '../images/icons/facebook.svg'
import Linkedin from '../images/icons/linkedin.svg'
import Instagram from '../images/icons/instagram.svg'
import Medium from '../images/icons/medium.svg'
import Menu from '../images/icons/menu.svg'
import Close from '../images/icons/close.svg'

class Header extends React.Component {

  state = {
    menuActive: false,
  }

  state = {
    languageMenuActive: false,
  }

  render() {
    return (
      <header>
        <Link to="/" className="fixed ml-8 mt-4 h-20 block z-40">
          <img src={WelementLogo} alt="Welement" className="h-11" />

        </Link>
        <div className="pr-8 h-20 flex items-center fixed w-full bg-welement-white z-10">

          <div className="flex-none">

          </div>
          <div className="flex-auto text-right">
            <Link to="/solutions" className="hidden sm:inline-block text-base font-bold uppercase mr-4" activeClassName="border-b-2 border-welement-black">Solutions</Link>
            <Link to="/prefab" className="hidden md:inline-block text-base font-bold uppercase mr-4" activeClassName="border-b-2 border-welement-black">Prefab</Link>
            <Link to="/portfolio" className="hidden sm:inline-block text-base font-bold uppercase mr-4" activeClassName="border-b-2 border-welement-black">Portfolio</Link>
            <Link to="/contact" className="!hidden sm:!inline-block btn-sm !text-base btn-black mr-2">Contact</Link>
            <button className="btn-sm !text-base btn-black-outline"
              onClick={() => this.setState({ languageMenuActive: !this.state.languageMenuActive })}>EN</button>
            <button
              className="ml-2 inline-block align-middle h-8"
              onClick={() => this.setState({ menuActive: !this.state.menuActive })}>
              <div className={this.state.menuActive ? "opacity-0" : "inline-block"}>
                <img src={Menu} alt="Open Menu" className="h-8" />
              </div>
            </button>
          </div>
        </div>

        <div className={this.state.languageMenuActive ? "block" : "hidden"}>
          <div className="bg-welement-black fixed top-0 left-0 h-full w-full block z-40 opacity-80">a</div>
          <div className="block fixed w-full sm:w-auto h-full sm:h-auto sm:top-1/2 sm:left-1/2 sm:transform sm:-translate-x-1/2 sm:-translate-y-1/2  p-12 sm:rounded-lg text-center bg-welement-white opacity-95 z-50">

            <button
              className="absolute top-4 right-4"
              onClick={() => this.setState({ languageMenuActive: !this.state.languageMenuActive })}>
              <img src={Close} alt="Close Menu" className="h-8" />
            </button>

            <div className="grid justify-items-center items-center h-full">
              <div className="">
                <h4 className="title-xs wel-mb-xs">Select Your Language</h4>
                <ul className="body-l text-center">
                  <li className="border-b border-gray-300 py-4 px-12"><a href="/" className="opacity-100">🇬🇧 In English</a></li>
                  <li className="border-b border-gray-300 py-4 px-12"><a href="/et" className="opacity-50">🇪🇪 Eesti keeles</a></li>
                  <li className="pt-4 px-8"><a href="/no" className="opacity-50">🇳🇴 På norsk</a></li>
                </ul>
              </div>
            </div>
          </div>

        </div>

        <div className={this.state.menuActive ? "block" : "hidden"}>
          <div className="transition-all duration-700 ease-in-out fixed top-0 left-0 w-full h-full bg-welement-white opacity-95 z-30">


            <button
              className="absolute top-6 right-8"
              onClick={() => this.setState({ menuActive: !this.state.menuActive })}>
              <img src={Close} alt="Close Menu" className="h-8" />
            </button>
            <div className="mx-auto px-8 sm:px-20 lg:px-40 pt-20 md:pt-40">
              <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                <div>
                  <ul>
                    <li className="mb-2">
                      <Link to="/" className="body-xl" activeClassName="border-b-2 border-welement-black">Home</Link>
                    </li>
                    <li className="mb-2">
                      <Link to="/solutions" className="body-xl" activeClassName="border-b-2 border-welement-black">Solutions</Link>
                    </li>
                    <li className="mb-2">
                      <Link to="/prefab" className="body-xl" activeClassName="border-b-2 border-welement-black">Prefab</Link>
                    </li>
                    <li className="mb-2">
                      <Link to="/our-story" className="body-xl" activeClassName="border-b-2 border-welement-black">Our Story</Link>
                    </li>
                    <li className="mb-2">
                      <Link to="/portfolio" className="body-xl" activeClassName="border-b-2 border-welement-black">Portfolio</Link>
                    </li>
                    <li className="mb-2">
                      <Link to="/contact" className="body-xl" activeClassName="border-b-2 border-welement-black">Contact</Link>
                    </li>
                  </ul>
                </div>

                <div>
                  <div className="wel-mb-s">
                    <h4 className="title-xs wel-mb-xs">Contact</h4>
                    <p><a href="tel:+37253334302" className="link">(+372) 5333 4302</a><br />
                      <a href="mailto:info@welement.ee" className="link">info@welement.ee</a><br />
                      Klaasi 14, Tartu<br />Estonia, EU</p>
                  </div>

                  <div className="wel-mb-m">
                    <a href="https://www.linkedin.com/company/welement-as/" className="inline-block mr-1"><img src={Linkedin} alt="LinkedIn" className="h-10" /></a>
                    <a href="https://www.facebook.com/welementprefab/" className="inline-block mr-1"><img src={Facebook} alt="Facebook" className="h-10" /></a>
                    <a href="https://www.instagram.com/welement_prefab/" className="inline-block mr-1"><img src={Instagram} alt="Instagram" className="h-10" /></a>
                    <a href="https://medium.com/welement" className="inline-block"><img src={Medium} alt="Medium" className="h-10" /></a>
                  </div>

                  <div className="wel-mb-m">
                    <h4 className="title-xs wel-mb-xs">Language</h4>
                    <ul>
                      <li><a href="/" className="opacity-100">🇬🇧 In English</a></li>
                      <li><a href="/et" className="opacity-50">🇪🇪 Eesti keeles</a></li>
                      <li><a href="/no" className="opacity-50">🇳🇴 På norsk</a></li>
                    </ul>
                  </div>
                </div>

              </div>

            </div>
          </div>

        </div>
        <div className="h-20 block"></div>

      </header>
    )
  }

}

export default Header
